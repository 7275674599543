// dependencies
import React, { Fragment } from "react";
import { View } from "react-native";
import { StyleSheet, css } from "aphrodite";

// components
import BaseComponent from "@cloudspire/legacy-resources/src/components/BaseComponent2";
import Link from "@cloudspire/legacy-resources/src/components/Link2";
import Carousel from "@cloudspire/legacy-resources/src/components/Carousel";
import FlexEmbed from "@cloudspire/legacy-resources/src/components/FlexEmbed2";
import { Row, Col } from "@cloudspire/legacy-resources/src/components/Grid2";
import Image from "@cloudspire/legacy-resources/src/components/Image";
import RoomRanking from "@cloudspire/legacy-resources/src/components/RoomRanking";
import Stack from "@cloudspire/legacy-resources/src/components/Stack";
import RoomMetaList from "@cloudspire/legacy-resources/src/components/RoomMetaList";
import RoomTrophy from "@cloudspire/legacy-resources/src/components/RoomTrophy";
import RoomNewTag from "@cloudspire/legacy-resources/src/components/RoomNewTag";
import RoomAllInclusiveRate from "@cloudspire/legacy-resources/src/components/RoomAllInclusiveRate";
import RoomBookableOnlineTag from "@cloudspire/legacy-resources/src/components/RoomBookableOnlineTag";
import RoomRateAndReviews from "@cloudspire/legacy-resources/src/components/RoomRateAndReviews";
import RoomPricing from "@cloudspire/legacy-resources/src/components/RoomPricing";
import RoomHeader from "./Header";
import CarouselLeftNavigation from "./CarouselLeftNavigation";
import CarouselRightNavigation from "./CarouselRightNavigation";

// contexts
import { useConfiguration } from "../../contexts/configuration";

// helpers
import {
  roomHasRanking,
  roomIsAllInclusiveRate,
} from "@cloudspire/legacy-resources/src/helpers/room";
import { roomIsBookableOnline } from "../../helpers/room";
import roomGetTrophyList from "../../helpers/room/getTrophyList";

const getImageRatio = ({ type }) => {
  switch (type) {
    case "block": {
      return 3 / 4;
    }

    case "block-compact": {
      return 9 / 16;
    }
  }
};

const styles = StyleSheet.create({
  roomBlock: {
    height: "100%",
    backgroundColor: "#ffffff",
    flexDirection: "column",
  },
  roomBlockChildren: {
    height: "100%",
  },
  blockContainerStyle: {
    height: "100%",
  },
  media: {
    position: "relative",
    display: "flex",
    width: "100%",
  },
  information: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: "0%",
  },
  bookableOnline: {
    position: "absolute",
    top: "1rem",
    left: "1rem",
  },
  trophyList: {
    position: "absolute",
    bottom: "1rem",
    right: 0,
    left: 0,
  },
  content: {
    paddingRight: "0.3125rem",
    paddingLeft: "0.3125rem",
  },
  footer: {
    paddingTop: "0.3125rem",
    paddingRight: "0.3125rem",
    paddingBottom: "0.3125rem",
    paddingLeft: "0.3125rem",
    borderStyle: "solid",
    borderTopWidth: 0,
    borderRightWidth: 0,
    borderBottomWidth: "0.1875rem",
    borderLeftWidth: 0,
    borderBottomLeftRadius: "0.1875rem",
    borderBottomRightRadius: "0.1875rem",
  },
  equalizer: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: "0%",
  },
  feedback: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  roomMetaList: {
    fontSize: "0.75rem",
  },
});

type PropsType = {
  flux: "block" | "block-compact";
  to?: string;
  room: ReturnType<
    typeof import("@cloudspire/legacy-resources/src/normalizers/room").roomParseData
  >;
  pricing?: ReturnType<
    typeof import("@cloudspire/legacy-resources/src/normalizers/pricing/estimate").pricingEstimateParseData
  >;
  linkProps?: any;
  context?: "home" | "search" | "campaign" | "lastSeen";
  shouldRenderCarouselNavigation?: boolean;
  onPress?;
};

const RoomBlock: React.FunctionComponent<PropsType> = React.memo(
  function RoomBlock(props) {
    const {
      to,
      flux = "block",
      pricing,
      room,
      linkProps,
      context,
      shouldRenderCarouselNavigation = false,
      onPress,
    } = props;

    const ratio = getImageRatio({ type: flux });

    const configuration = useConfiguration();

    const allInclusiveRate = roomIsAllInclusiveRate({ room });

    const roomTrophyList = roomGetTrophyList({ room });

    return (
      <BaseComponent aStyle={styles.roomBlock}>
        <div className={css(styles.media)}>
          <FlexEmbed ratio={ratio}>
            <Carousel
              shouldAutoplay={false}
              leftNavigation={
                shouldRenderCarouselNavigation ? (
                  <CarouselLeftNavigation />
                ) : null
              }
              rightNavigation={
                shouldRenderCarouselNavigation ? (
                  <CarouselRightNavigation />
                ) : null
              }
            >
              {room.pictureList.map((media, index): JSX.Element => {
                return (
                  <Fragment key={index}>
                    {to ? (
                      <Link {...linkProps} target="_blank" to={to}>
                        <View style={{ width: "100%" }}>
                          <Image
                            resizeMode="cover"
                            ratio={ratio}
                            source={{ uri: media.urls.small }}
                            accessibilityLabel={media.caption ?? room?.name}
                          />
                        </View>
                      </Link>
                    ) : (
                      <View style={{ width: "100%" }}>
                        <Image
                          resizeMode="cover"
                          ratio={ratio}
                          source={{ uri: media.urls.small }}
                          accessibilityLabel={media.caption ?? room?.name}
                        />
                      </View>
                    )}
                  </Fragment>
                );
              })}
            </Carousel>
          </FlexEmbed>

          {flux === "block" &&
            roomIsBookableOnline({ room, configuration }) && (
              <div className={css(styles.bookableOnline)}>
                <RoomBookableOnlineTag />
              </div>
            )}

          {flux === "block" && roomTrophyList.length > 0 && (
            <div className={css(styles.trophyList)}>
              <Row
                type="inline"
                flexWrap={true}
                gutter={0.5}
                style={{ justifyContent: "flex-end" }}
              >
                {roomTrophyList.map((trophy, index) => (
                  <Col key={index}>
                    <RoomTrophy trophy={trophy} />
                  </Col>
                ))}
              </Row>
            </div>
          )}
        </div>

        <div className={css(styles.information)}>
          <Stack marginTop={0.625} />

          <div className={css(styles.content)}>
            <View>
              <RoomHeader
                linkProps={linkProps}
                reference={room.reference}
                flux={flux}
                to={to}
                type={room.type}
                name={room.name}
                zipcode={room.zipcode}
                city={room.city}
                distance={room.distance}
                registrationNumber={room.registrationNumber}
                context={context}
                onPress={onPress}
              />
            </View>

            <Stack marginTop={0.625} />

            {flux === "block" && (
              <Fragment>
                <div className={css(styles.feedback)}>
                  {roomHasRanking({ room }) && (
                    <div>
                      <RoomRanking
                        ranking={room.ranking}
                        color={room.bestColor}
                      />

                      <Stack marginTop={0.5} />
                    </div>
                  )}

                  <div>
                    <Row type="inline" flexWrap={true} gutter={0.625}>
                      {room.recentlyAdded && (
                        <Col>
                          <RoomNewTag />
                        </Col>
                      )}

                      {allInclusiveRate && (
                        <Col>
                          <RoomAllInclusiveRate />
                        </Col>
                      )}

                      {room.reviews > 0 && (
                        <Col>
                          <RoomRateAndReviews
                            rate={room.rate}
                            reviews={room.reviews}
                          />

                          <Stack marginTop={0.5} />
                        </Col>
                      )}
                    </Row>
                  </div>
                </div>

                <Stack marginTop={0.5} />

                <RoomMetaList
                  aStyle={styles.roomMetaList}
                  flux={flux}
                  categoryList={room.categoryList}
                  capacity={room.maxCapacity}
                  beds={room.beds}
                  chambers={room.chambers}
                  arePetsAllowed={room.arePetsAllowed}
                  surface={room.surface}
                  hostTable={room.hostTable}
                  handicapList={room.handicapList}
                />

                <Stack marginTop={0.5} />
              </Fragment>
            )}
          </div>

          <div className={css(styles.equalizer)} />

          <div
            className={css(styles.footer)}
            style={{ borderColor: room.bestColor }}
          >
            <RoomPricing pricing={pricing} />
          </div>
        </div>
      </BaseComponent>
    );
  }
);

export default RoomBlock;
