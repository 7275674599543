// dependencies
import React, { useMemo } from "react";
import { StyleSheet, css } from "aphrodite";

const styles = StyleSheet.create({
  flexEmbed: {
    position: "relative",
    overflow: "hidden",
    width: "100%",
  },
  content: {
    height: "100%",
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
});

type PropsType = {
  ratio?: number;
};

const FlexEmbed: React.FunctionComponent<PropsType> = (props) => {
  const { ratio, children } = props;

  const dynamicStyles = useMemo(
    () =>
      StyleSheet.create({
        ratio: {
          paddingBottom: `${ratio * 100}%`,
        },
      }),
    [ratio]
  );

  return (
    <div className={css(styles.flexEmbed)}>
      <div className={css(dynamicStyles.ratio)} />

      <div className={css(styles.content)}>{children}</div>
    </div>
  );
};

export default FlexEmbed;
