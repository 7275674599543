// dependencies
import React, { useMemo } from "react";
import { StyleSheet } from "aphrodite";

// components
import BaseComponent from "@cloudspire/legacy-resources/src/components/BaseComponent2";

type PropsType = {
  aStyle?;
};

const Placeholder: React.FunctionComponent<PropsType> = (props) => {
  const { aStyle } = props;

  const color = "#abc5b9";
  const fadeColor = "#eaf0ee";

  const animationStyles = useMemo(() => {
    const blinkKeyframes = {
      "0%": {
        backgroundColor: color,
      },

      "50%": {
        backgroundColor: fadeColor,
      },

      "100%": {
        backgroundColor: color,
      },
    };

    return StyleSheet.create({
      animation: {
        animationName: [blinkKeyframes],
        animationDuration: "1000ms",
        animationIterationCount: "infinite",
      },
    });
  }, [color, fadeColor]);

  return <BaseComponent aStyle={[animationStyles.animation, aStyle]} />;
};

export default Placeholder;
