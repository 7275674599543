// dependencies
import React from "react";
import { useIntl } from "react-intl";
import { StyleSheet, css } from "aphrodite";

// components
import { IconBolt } from "@cloudspire/legacy-svg-icon-library";
import { Row, Col } from "@cloudspire/legacy-resources/src/components/Grid2";
import Tag from "@cloudspire/legacy-resources/src/components/Tag";
import BaseComponent from "@cloudspire/legacy-resources/src/components/BaseComponent2";

const styles = StyleSheet.create({
  icon: {
    color: "#ffdf2f",
  },
});

type PropsType = {};

const RoomBookableOnlineTag: React.FunctionComponent<PropsType> = () => {
  const intl = useIntl();

  return (
    <BaseComponent>
      <Tag>
        <Row type="inline" gutter={0.1875}>
          <Col>
            <div className={css(styles.icon)}>
              <IconBolt />
            </div>
          </Col>

          <Col>
            {intl.formatMessage({
              id: "app.component.roomBookableOnline.bookableOnlineLabel",
            })}
          </Col>
        </Row>
      </Tag>
    </BaseComponent>
  );
};

export default RoomBookableOnlineTag;
