import React from "react";
import {
  StyleSheet,
  View,
  Animated,
  LayoutChangeEvent,
  TouchableWithoutFeedback,
  ViewStyle,
  TextStyle,
  TranslateXTransform,
  AccessibilityRole,
} from "react-native";

// libraries
import { noop } from "@cloudspire/legacy-shared/src/libraries";

// constants
import theming, { IPropsWithTheme } from "../../constants/theming";

// components
import Text from "../Text";

const { withTheme } = theming;

export type IProps = {
  shouldAutoplay?: boolean;
  autoplayOptions?: { delay: number };
  animationDuration?: number;
  rightNavigation?: React.ReactElement;
  leftNavigation?: React.ReactElement;
  style?: object;
  onChangeSlide?: (params: { index: number }) => void;
} & IPropsWithTheme;

type IState = {
  activeIndex: number;
  width: number;
};

class Carousel extends React.Component<IProps, IState> {
  public static defaultProps: Partial<IProps>;

  public readonly state: IState = {
    activeIndex: 0,
    width: 0,
  };
  private readonly translateX = new Animated.Value(0);
  private autoplayId: number;

  public constructor(props: IProps) {
    super(props);

    this.nextPage = this.nextPage.bind(this);
    this.previousPage = this.previousPage.bind(this);
    this.initAutoplay = this.initAutoplay.bind(this);
    this.terminateAutoplay = this.terminateAutoplay.bind(this);
    this.handleLayout = this.handleLayout.bind(this);
  }

  public componentDidMount(): void {
    const { shouldAutoplay } = this.props;

    if (shouldAutoplay) {
      this.initAutoplay();
    }
  }

  public componentWillUnmount(): void {
    this.terminateAutoplay();
  }

  public componentDidUpdate(prevProps): void {
    const { onChangeSlide } = this.props;
    const { activeIndex } = this.state;
    const slideCount = this.getSlideCount();
    const previousSlideCount = React.Children.count(prevProps.children);

    if (prevProps.shouldAutoplay !== this.props.shouldAutoplay) {
      if (this.props.shouldAutoplay) {
        this.initAutoplay();
      } else {
        this.terminateAutoplay();
      }
    }

    if (
      previousSlideCount > slideCount &&
      activeIndex >= previousSlideCount - 1
    ) {
      // si une page est supprimée, on détecte que le state n'est plus cohérent
      // on le met à jour

      let newActiveIndex = slideCount - 1;
      if (newActiveIndex < 0) {
        newActiveIndex = 0;
      }

      this.setState({ activeIndex: newActiveIndex });
      onChangeSlide({ index: newActiveIndex });
    }
  }

  /**
   * Initialise (et lance) l'autoplay
   */
  private initAutoplay(): void {
    const { autoplayOptions, animationDuration } = this.props;
    const { delay } = autoplayOptions;

    if (null == this.autoplayId) {
      this.autoplayId = setInterval(this.nextPage, delay + animationDuration);
    }
  }

  /**
   * Supprime l'autoplay
   */
  private terminateAutoplay(): void {
    clearInterval(this.autoplayId);
    this.autoplayId = null;
  }

  /**
   * Réinitialise le compteur de l'autoplay
   */
  private resetAutoplay(): void {
    this.terminateAutoplay();
    this.initAutoplay();
  }

  public getStyles(): StyleSheet.NamedStyles<{
    text: TextStyle;
    view: ViewStyle;
    animatedView: ViewStyle;
    slide: ViewStyle;
    child: ViewStyle;
    navigationView: ViewStyle;
    leftNavigationView: ViewStyle;
    rightNavigationView: ViewStyle;
  }> {
    const { theme } = this.props;

    return StyleSheet.create({
      text: {
        display: "flex",
        flexGrow: 1,
        fontSize: `${theme.FONT_SIZE / 16}rem`,
        height: "100%",
      },
      view: {
        position: "relative",
        width: "100%",
        height: "100%",
        overflow: "hidden",
      },
      animatedView: {
        width: "100%",
        height: "100%",
        flexDirection: "row",
        left: (-this.state.activeIndex - 1) * this.state.width,
        transform: [
          { translateX: this.translateX } as unknown as TranslateXTransform,
        ],
      },
      slide: {
        width: "100%",
        height: "100%",
      },
      child: {
        width: "100%",
        height: "100%",
      },
      navigationView: {
        position: "absolute",
        top: "50%",
        zIndex: 29,
        transform: [{ translateY: "-50%" } as unknown as TranslateXTransform],
      },
      leftNavigationView: {
        left: 0,
      },
      rightNavigationView: {
        right: 0,
      },
    });
  }

  private getSlideCount(): number {
    const { children } = this.props;

    return React.Children.count(children);
  }

  /**
   * Affiche la page suivante.
   * @param {object} param0
   * @param {object} param0.resetAutoplay L'autoplay doit-il est réinitialisé ?
   *   Utile dans le cas où on appuie sur une flèche de navigation.
   *   On ne souhaite pas que l'autoplay s'active en même temps
   */
  public nextPage({ resetAutoplay = true } = {}): void {
    this.setPage({ newActiveIndex: this.state.activeIndex + 1, resetAutoplay });
  }

  /**
   * Affiche la page précédente.
   * @param {object} param0
   * @param {object} param0.resetAutoplay L'autoplay doit-il est réinitialisé ?
   *   Utile dans le cas où on appuie sur une flèche de navigation.
   *   On ne souhaite pas que l'autoplay s'active en même temps
   */
  public previousPage({ resetAutoplay = true } = {}): void {
    this.setPage({ newActiveIndex: this.state.activeIndex - 1, resetAutoplay });
  }

  /**
   * Affiche la n-ème page.
   * @param {object} param0
   * @param {object} param0.newActiveIndex L'index de la nouvelle page à afficher.
   * @param {object} param0.resetAutoplay L'autoplay doit-il est réinitialisé ?
   *   Utile dans le cas où on appuie sur une flèche de navigation.
   *   On ne souhaite pas que l'autoplay s'active en même temps
   */
  public setPage({ newActiveIndex, resetAutoplay = true }: any = {}): void {
    const { animationDuration, shouldAutoplay, onChangeSlide } = this.props;
    const { activeIndex } = this.state;

    const slideCount = this.getSlideCount();

    if (slideCount <= 1) {
      return;
    }

    // récupère le nouvel index réel
    const localNewActiveIndex = (newActiveIndex + slideCount) % slideCount;

    // récupère le décalage à appliquer (1 ou -1 si page précédante ou page suivante)
    const shift = (newActiveIndex - activeIndex) % (slideCount + 1);

    if (resetAutoplay && shouldAutoplay) {
      this.resetAutoplay();
    }

    Animated.timing(this.translateX, {
      toValue: -shift * this.state.width,
      duration: animationDuration,
      useNativeDriver: false,
    }).start(() => {
      this.translateX.setValue(0);
      this.setState({ activeIndex: localNewActiveIndex });
      onChangeSlide({ index: localNewActiveIndex });
    });
  }

  private handleLayout(event: LayoutChangeEvent): void {
    this.setState({ width: event.nativeEvent.layout.width });
  }

  private renderSlide({ key, child, styles }): JSX.Element {
    const { activeIndex } = this.state;

    return (
      <View key={key} style={styles.slide} aria-hidden={key !== activeIndex}>
        {React.cloneElement(child as React.ReactElement, {
          style: styles.child,
        })}
      </View>
    );
  }

  public render(): JSX.Element {
    const { leftNavigation, rightNavigation, children } = this.props;

    const childrenCount = React.Children.count(children);
    const childrenArray = React.Children.toArray(children);

    const styles = this.getStyles();

    return (
      <Text style={styles.text}>
        <View
          style={styles.view}
          onLayout={this.handleLayout}
          accessibilityRole={"tabpanel" as AccessibilityRole}
        >
          {undefined !== leftNavigation && (
            <TouchableWithoutFeedback
              onPress={() => this.previousPage({ resetAutoplay: true })}
            >
              <View style={[styles.navigationView, styles.leftNavigationView]}>
                <Text>{leftNavigation}</Text>
              </View>
            </TouchableWithoutFeedback>
          )}

          <Animated.View style={styles.animatedView}>
            {childrenCount > 0 &&
              this.renderSlide({
                child: childrenArray[childrenCount - 1],
                key: "previous",
                styles,
              })}
            {childrenArray.map((child, index) => {
              return this.renderSlide({ child, key: index, styles });
            })}
            {childrenCount > 0 &&
              this.renderSlide({
                child: childrenArray[0],
                key: "next",
                styles,
              })}
          </Animated.View>

          {undefined !== rightNavigation && (
            <TouchableWithoutFeedback
              onPress={() => this.nextPage({ resetAutoplay: true })}
            >
              <View style={[styles.navigationView, styles.rightNavigationView]}>
                {rightNavigation}
              </View>
            </TouchableWithoutFeedback>
          )}
        </View>
      </Text>
    );
  }
}

Carousel.defaultProps = {
  shouldAutoplay: true,
  autoplayOptions: { delay: 3000 },
  animationDuration: 320,
  style: {},
  onChangeSlide: noop,
};

export default withTheme(Carousel);
