// dependencies
import React from "react";
import { View } from "react-native";

// components
import Block from "./Block2";
import Inline from "./Inline2";

function getComponent({ flux }) {
  switch (flux) {
    case "block":
    case "block-compact": {
      return Block;
    }

    case "inline": {
      return Inline;
    }
  }

  return () => null;
}

function RoomCard(props) {
  const {
    flux = "block",
    room,
    to,
    pricing,
    context,
    linkProps,
    shouldRenderCarouselNavigation = false,
    onPress,
  } = props;

  const RoomComponent = getComponent({ flux });

  return (
    <View
      style={{ height: "100%" }}
      itemScope="itemScope"
      itemType="https://schema.org/Accommodation https://schema.org/Product"
    >
      <>
        {room.arePetsAllowed && (
          <meta
            itemProp="petsAllowed"
            content={room.arePetsAllowed.toString()}
          />
        )}
        <meta itemProp="numberOfRooms" content="1" />
        <meta itemProp="productID" content="slug" />
        <meta itemProp="url" content="slug" />
        <meta itemProp="name" content="slug" />
        <meta itemProp="image" content="slug" />
        <meta itemProp="description" content="slug" />
      </>

      <RoomComponent
        flux={flux}
        room={room}
        to={to}
        pricing={pricing}
        linkProps={linkProps}
        context={context}
        shouldRenderCarouselNavigation={shouldRenderCarouselNavigation}
        onPress={onPress}
      />
    </View>
  );
}

export default RoomCard;
