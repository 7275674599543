// dependencies
import React from "react";
import { StyleSheet, css } from "aphrodite";
import { IconAngleLeft } from "@cloudspire/legacy-svg-icon-library";

// components
import BaseComponent from "@cloudspire/legacy-resources/src/components/BaseComponent2";

type PropsType = {};

const styles = StyleSheet.create({
  carouselLeftNavigation: {
    position: "relative",
    marginLeft: "1rem",
    zIndex: 1,
    cursor: "pointer",
  },
  icon: {
    fontSize: "1.875rem",
    color: "#ffffff",
    zIndex: 1,
  },
  iconShadow: {
    fontSize: "1.875rem",
    position: "absolute",
    transform: "translateX(0.0625rem)",
  },
});

const CarouselLeftNavigation: React.FunctionComponent<PropsType> = () => {
  return (
    <BaseComponent aStyle={styles.carouselLeftNavigation}>
      <div className={css(styles.icon)}>
        <IconAngleLeft />
      </div>

      <div className={css(styles.iconShadow)}>
        <IconAngleLeft />
      </div>
    </BaseComponent>
  );
};

export default CarouselLeftNavigation;
